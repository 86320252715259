import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

import { getCreationSlug, getTagSlug } from "src/helpers/slug"

const Container = styled.div<{ $visible?: boolean }>`
  position: ${({ $visible }) => ($visible ? `static` : `absolute`)};
  bottom: 5px;
  left: ${({ $visible }) => ($visible ? 0 : -1000)}px;
  margin-left: 10px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row-reverse;
    justify-content: ${({ $visible }) => ($visible ? `center` : `flex-end`)};
    flex-wrap: wrap-reverse;

    li {
      position: relative;
      padding: 4px 8px;
      background-color: rgba(255, 255, 255);
      margin-right: 5px;
      margin-bottom: 5px;

      a {
        text-decoration-color: transparent;
        text-transform: uppercase;
        position: relative;
        letter-spacing: 0.15em;
        font-weight: bold;
        color: #000000;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`
interface Props {
  node: Production
  visible?: boolean
}

const Tags = ({ node, visible }: Props) => {
  return (
    <Container $visible={visible}>
      <ul>
        {node.links &&
          node.links.map((link) => {
            const title = (link as Tag).name || (link as Creation).title
            const slug = (link as Tag).name ? getTagSlug(title) : getCreationSlug(title)
            return (
              <li key={link.id}>
                <Link to={`/${slug}`}>{title}</Link>
              </li>
            )
          })}
      </ul>
    </Container>
  )
}
export default Tags
