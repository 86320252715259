import React from "react"
import styled from "styled-components"

import Layout from "src/layout"

import Gallery from "../components/Gallery"

const Description = styled.div`
  max-width: 1200px;
  padding: 0 10px;
  margin: 0 auto;
  width: 100%;
`

const GalleryTemplate = ({ pageContext: { title, content, slug, productions }, data }) => {
  return (
    <Layout title={title}>
      {content && <Description>{content}</Description>}
      <Gallery slug={slug} productions={productions} />
    </Layout>
  )
}

export default GalleryTemplate
