import styled from "styled-components"

import { youtube_parser } from "src/helpers/youtube"

const Iframe = styled.iframe<{ $height: number }>`
  width: 100%;
  height: ${({ $height }) => $height}px;
  margin-left: 0px;
`

interface Props {
  production: Production
  height: number
}

const VideoPlayer = ({ height, production }: Props) => {
  const embedId = youtube_parser(production.youtube)

  if (!embedId) {
    return null
  }

  return (
    <Iframe
      src={`https://www.youtube.com/embed/${embedId}?rel=0&autoplay=0controls=0`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      $height={height}
    />
  )
}

export default VideoPlayer
